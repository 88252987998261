import Router from 'vue-router';

const body = () =>
  import(
    /* webpackChunkName: "landing-routes" */ '@fremcast/template/DinamicSectionLoader'
    );

const header = () =>
  import(
    /* webpackChunkName: "landing-routes" */ '@fremcast/template/DinamicHeaderLoader'
    );

const footer = () =>
  import(
    /* webpackeChunkName: "landing-routes" */ '@fremcast/template/DinamicFooterLoader'
    )

const router = new Router({
  mode: 'history',
  base: '/',
  //base: process.env.BASE_URL,
  routes: [
    {
      path: '/preview/:sectionId/:blockId/:videoId',
      name: 'preview',
      component: () =>
        import(
          /* webpackChunkName: "routes" */
          '@fremcast/template/DinamicVideoPreview.vue'
          ),
      props: true
    },
    {
      path: '/:slug?',
      name: 'pages',
      components: {
        headerCms: header,
        default: body,
        footerCms: footer
      }
    }
  ]
});

// ...pageBuilderRoutes

// This callback runs before every route change, including on initial load
router.beforeEach((to, from, next) => {
  //const routeMeta = to.meta as RouteMeta;
  //store.dispatch('topToolbar/changeTitle', routeMeta.title);
  next();
});

export default router;
