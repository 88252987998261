import Vue from 'vue'
import App from './App.vue'
import vuetify from '@fremcast/landing/lib/plugins/vuetify'
import "@fremcast/landing/lib/plugins/vuex";
import store from "@fremcast/landing/lib/store/index";
import "@fremcast/landing/lib/plugins/axios";
import router from "./router";
import site_prod from './api/site'
import site_dev from './api/site_dev'
let site
if (process.env.VUE_APP_ENVIRONMENT === 'production') {
  site = site_prod
} else {
  site = site_dev
}

//import router from "@fremcast/landing/lib/router"

import { Plugin } from 'vue-fragment'
Vue.use(Plugin)

import VueYoutube from 'vue-youtube'
Vue.use(VueYoutube)

import VueMq from 'vue-mq'

Vue.use(VueMq, {
  defaultBreakpoint: 'default',
  breakpoints: {
    xsDown: 599,
    xsUp: 600,
    smDown: 959,
    smUp: 960,
    mdDown: 1279,
    mdUp: 1280,
    lgDown: 1919,
    lgUp: 1920,
    xl: Infinity
  }
});

Vue.config.productionTip = false
window._ = require('lodash');

window.apiUrl = site[0].apiUrl

Vue.prototype.$fremcast = {
  globalSite: [],
  currentPage: {},
  apiUrl: site[0].apiUrl,
  site
}

new Vue({
  vuetify,
  store,
  router,
  render: h => h(App)
}).$mount('#app')
