export default [
  {
    domain: 'test.fremcast.com',
    uuid: process.env.VUE_APP_API_WEBSITE_UUID,
    apiUrl: process.env.VUE_APP_API_URL
  },
  {
    domain: 'dcastrili.com',
    uuid: '8a4ca32e-54ea-4234-901e-dd05deae3fd0',
    apiUrl: process.env.VUE_APP_API_URL
  }
]
//uuid: '57f85714-3470-48d3-bbd5-101d7146e618' //prod
//uuid: '2035bdd2-f1f3-40cb-878e-f5881f97be28'
//uuid: 'e711b620-cff2-499d-9954-10886ad31000' //local
// uuid: 'e72fc2ae-4198-4d6b-b8e1-0b168219fadc' //demo
