<template>
  <template-loader
    v-if="ready"
    theme="skrn"
    :showHeader="true"
    :showSidebar="false"
    :showFooter="true"
    :isLanding="true"
    :uuid="fetchPageByUuid"
    :pages="fetchPages"
  />
</template>

<script>
  import page from '@fremcast/section/mixins/page';

  export default {
    name: "PageRendering",

    data() {
      return {
        ready: false
      }
    },
    created() {
      let self = this
      let domain = (new URL(window.location.href));
      console.log('domain', domain)
      const site = this.$fremcast.site.find(item => item.domain === domain.hostname)

      this.$store.dispatch('website/find', site.uuid).then(() => {
       self.ready = true
      })
    },
    props: {
      uuid: String
    },

    mixins: [
      page
    ],

    components: {
      TemplateLoader: () => import("@fremcast/template/DinamicTemplateLoader")
    }
  }
</script>
